import { docs_v1 } from "googleapis"
import { encodePathParameter, GAPI_SkipProps, GoogleAPIResource } from "./_base.ts"

export class DocsV1 {
	constructor(
		private getAccessToken: () => Promise<string | null | undefined>
	) {}

	get documents() { return new DocsV1$Documents(this.getAccessToken) }
}

type DocsV1_SkipProps = 'documentId' | GAPI_SkipProps

class DocsV1$Documents extends GoogleAPIResource {
	constructor(
		getAccessToken: () => Promise<string | null | undefined>,
	) {
		super(getAccessToken, 'https://docs.googleapis.com/v1/')
	}

	async create(data: Omit<docs_v1.Schema$Document, 'documentId'>, options: Omit<docs_v1.Params$Resource$Documents$Create, DocsV1_SkipProps> = {}) {
		return await this._request<docs_v1.Schema$Document>('POST', `documents`, options, data)
	}

	async get(ID: string, options: Omit<docs_v1.Params$Resource$Documents$Get, DocsV1_SkipProps> = {}) {
		return await this._request<docs_v1.Schema$Document>('GET', `documents/${encodePathParameter(ID)}`, options)
	}

	async batchUpdate(ID: string, data: docs_v1.Schema$BatchUpdateDocumentRequest, options: Omit<docs_v1.Params$Resource$Documents$Batchupdate, DocsV1_SkipProps> = {}) {
		return await this._request<docs_v1.Schema$BatchUpdateDocumentResponse>('POST', `documents/${encodePathParameter(ID)}:batchUpdate`, options, data)
	}
}

import { useQuery, useMutation } from "@tanstack/react-query"
import ky from "ky"
import { Temporal } from "@js-temporal/polyfill"
import { AppointmentInfo, Intent, ServiceInfo } from "@imago/model"
import { useAppointmentsCacheManager, useServiceAppointment } from "../api.ts"

const enableYCBMAPI = true

declare const YCBM_AvailabilityKey__Brand: unique symbol;
type YCBM_AvailabilityKey = string & {[YCBM_AvailabilityKey__Brand]: never}

interface YCBM_IntentAvailabilityKeyResponse {
	key: YCBM_AvailabilityKey
}

interface YCBM_AvailabilitiesResponse {
	slots: {
		freeUnits: number
		startsAt: string
	}[]
}

export function useBookableSlots(intent: Intent | undefined) {
	const ycbmIntentID = intent?.ycbm_secret

	return useQuery({
		queryKey: ['bookable-slots', ycbmIntentID],
		queryFn: async (): Promise<Temporal.ZonedDateTime[]> => {
			if (!intent) {
				return []
			}
			const data = await ky.get(`https://api.youcanbook.me/v1/intents/${ycbmIntentID}/availabilitykey`).json<YCBM_IntentAvailabilityKeyResponse>()
			const data2 = await ky.get(`https://api.youcanbook.me/v1/availabilities/${data.key}`).json<YCBM_AvailabilitiesResponse>()
			return data2.slots.map(slot => Temporal.Instant.fromEpochNanoseconds(BigInt(slot.startsAt) * BigInt('1000000')).toZonedDateTimeISO(Temporal.Now.timeZoneId()))
		}
	})
}

export function useBookMutation({service, intent}: {
	service: ServiceInfo
	intent?: Intent
}) {
	const ycbmIntentID = intent?.ycbm_secret
	const isIntentBooked = useServiceAppointment(service.ID)

	const cacheManager = useAppointmentsCacheManager()
	const mutation = useMutation({
		mutationFn: async ({slot}: {
			slot: Temporal.ZonedDateTime
		}) => {
			try {
				if (enableYCBMAPI) {
					// Split into two calls because of YCBM bug.
					await ky.patch(`https://api.youcanbook.me/v1/intents/${ycbmIntentID}/selections`, {
						json: {
							startsAt: slot.toInstant().epochMilliseconds,
							timeZone: slot.timeZoneId,
						}
					}).json()
					await ky.patch(`https://api.youcanbook.me/v1/intents/${ycbmIntentID}/selections`, {
						json: {
							teamMemberId: "AUTO",
						}
					}).json()
					await ky.patch(`https://api.youcanbook.me/v1/intents/${ycbmIntentID}/confirm`, {
						json: {}
					}).json()
				}

				const appointment: AppointmentInfo = {
					serviceID: service.ID,
					duration: service.duration,
					start: slot,
					end: slot.add(service.duration),
					ycbm_secret: intent?.ycbm_secret
					// TODO googleMeetLink
				}
				cacheManager.add(appointment)
				return appointment
			} finally {
				cacheManager.invalidate()
			}
		}
	})
	return ycbmIntentID && !isIntentBooked ? mutation : undefined
}

export function useCancelBookingMutation({appointment}: {
	appointment: AppointmentInfo | undefined
}) {
	const ycbmIntentID = appointment?.ycbm_secret
	const isIntentBooked = useServiceAppointment(appointment?.serviceID)

	const cacheManager = useAppointmentsCacheManager()
	const mutation = useMutation({
		mutationFn: async () => {
			try {
				if (enableYCBMAPI) {
					await ky.patch(`https://api.youcanbook.me/v1/intents/${ycbmIntentID}/booking/cancel`, {
						json: {}
					})
				}

				cacheManager.remove(appointment!)
			} finally {
				cacheManager.invalidate()
			}
		}
	})

	return ycbmIntentID && isIntentBooked ? mutation : undefined
}

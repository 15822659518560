import { calendar_v3 } from "googleapis"
import { encodePathParameter, GAPI_SkipProps, GoogleAPIResource } from "./_base.ts"

export class CalendarV3 {
	constructor(
		private getAccessToken: () => Promise<string | null | undefined>,
	) {
	}

	get events() {return new CalendarV3$Events(this.getAccessToken)}
}

type CalendarV3$Events$SkipProps = 'calendarId' | 'eventId' | GAPI_SkipProps

class CalendarV3$Events extends GoogleAPIResource {
	constructor(
		getAccessToken: () => Promise<string | null | undefined>,
	) {
		super(getAccessToken, 'https://www.googleapis.com/calendar/v3/')
	}

	async list(
		calendarID: string,
		options: Omit<calendar_v3.Params$Resource$Events$List, 'eventTypes' | 'privateExtendedProperty' | 'sharedExtendedProperty' | CalendarV3$Events$SkipProps> = {} // TODO add those options
	) {
		return await this._request<calendar_v3.Schema$Events>('GET', `calendars/${encodePathParameter(calendarID)}/events`, options)
	}

	async get(
		calendarID: string,
		ID: string,
		options: Omit<calendar_v3.Params$Resource$Events$Get, CalendarV3$Events$SkipProps> = {}
	) {
		return await this._request<calendar_v3.Schema$Event>('GET', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options)
	}

	async patch(
		calendarID: string,
		ID: string,
		data: Omit<calendar_v3.Schema$Event, 'id'>,
		options: Omit<calendar_v3.Params$Resource$Events$Patch, CalendarV3$Events$SkipProps> = {}) {
		return await this._request<calendar_v3.Schema$Event>('PATCH', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options, data)
	}
}
